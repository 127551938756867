@use "colors" as *;
@use "responsive" as *;
#err {
  margin: 4px 8px;
  h1 {
    color: white;
    font-size: 14pt;
    padding: 4px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 0 2px black;
    background-image: radial-gradient(at 10px 10px, $alizarin, $red);
  }
  p {
    font-size: 12pt;
    text-align: justify;
    hyphens: auto; -webkit-hyphens: auto; -moz-hyphens: auto; -ms-hyphens: auto;
  }
}
